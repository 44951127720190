import { Link } from '@remix-run/react';

export function BookDemoButton() {
  return (
    <Link
      to={'https://meetings.hubspot.com/arlen-marmel/general-meeting-rb'}
      className={`w-40 h-10 btn-delete rounded flex items-center justify-center text-sms font-bold font-Montserrat`}
      target='_blank'
    >
      Book a Demo
    </Link>
  );
}
